import React from "react";
import { useHistory } from "react-router-dom";
import { requestDismissWhatsNew } from "../../httpapi";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import md5 from "md5";
import Banner from "../Banner";
import { Link } from "@sussex/react-kit/elements";
import whatsnewIcon from "../../assets/whatsnew.svg";
import { datadogEvent } from "../../datadog";

const WhatsNewBanner = () => {
  const { room, setRoom } = useWaitingRoomContext();
  const history = useHistory();
  const [whatsnewBody, whatsnewMessage, whatsnewClickAction] = useCopy([
    "whatsnew.body",
    "whatsnew.message",
    "whatsnew.clickAction",
  ]);

  const version = md5(whatsnewBody);

  if (!room.setupReady || room.lastSeenVersion === version) {
    return null;
  }

  const dismiss = async () => {
    try {
      await requestDismissWhatsNew(room.roomAlias, version);

      datadogEvent({
        category: "whats_new",
        event: "version_updated",
        component: "WhatsNew.WhatsNewBanner",
      });

      setRoom(state => {
        return { ...state, lastSeenVersion: version };
      });
    } catch (error) {
      console.error("Could not update last seen version", error);
    }
  };

  const closeBanner = async () => {
    datadogEvent({
      category: "whats_new",
      feature: "close",
      event: "clicked",
      component: "WhatsNew.WhatsNewBanner",
    });

    await dismiss();
  };

  const seeUpdates = async () => {
    datadogEvent({
      category: "whats_new",
      feature: "see_updates",
      event: "clicked",
      component: "WhatsNew.WhatsNewBanner",
    });

    await dismiss();
    history.push("/dashboard/help?tab=whatsnew");
  };

  return (
    <Banner
      icon={whatsnewIcon}
      message={swapCopyVariables(whatsnewMessage, {
        CLICK_ACTION: <Link onClick={seeUpdates}>{whatsnewClickAction}</Link>,
      })}
      onClose={closeBanner}
    />
  );
};

export default WhatsNewBanner;
