import { appInstanceId } from "../App";

const actions = {
  AUTHORIZE_THERAPIST: "therapist",
  ROOM_DETAILS: "room",
  CHECKIN: "checkin",
  CREATE_SESSION: "create-session",
  END_SESSION: "delete-session",
  SEND_CHAT_MESSAGE: "send-chat-message",
  ADD_PARTICIPANTS: "add-participants",
  SEND_INVITATION: "send-invitation",
  SEND_FEEDBACK: "send-feedback",
  UPDATE_ROOM: "update-room",
  SESSION_HISTORY: "history",
  BAA: "baa",
  GET_PARTICIPANT_INFO: "participant-info",
};

const baseURL = `${process.env.REACT_APP_API_URL}/v2`;

const isRetryable = statusCode => statusCode >= 500 || statusCode === 429;

const xhrRequest = async ({ method, action, body, retry = 0 }) => {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    const url = baseURL.replace(/\/?$/, "/") + action;
    xhr.open(method, url);
    xhr.setRequestHeader("Content-Type", "application/json; utf-8");
    xhr.setRequestHeader("App-Instance-Id", appInstanceId);
    xhr.withCredentials = true;
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
        return;
      }
      if (!isRetryable(this.status) || retry > 2) {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          body: xhr.response,
        });
        return;
      }
      const newRetry = retry + 1;
      setTimeout(
        () => resolve(xhrRequest({ method, action, body, retry: newRetry })),
        100 * newRetry,
      );
    };
    xhr.onerror = function () {
      if (retry > 2) {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          body: "Network error",
        });
        return;
      }
      const newRetry = retry + 1;
      setTimeout(
        () => resolve(xhrRequest({ method, action, body, retry: newRetry })),
        100 * newRetry,
      );
    };
    if (body) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
};

const requestWaitingRoomDetails = async roomAlias => {
  const res = {
    success: true,
    roomAlias: "",
    therapistDisplayName: "",
    therapistPhotoUrl: "",
    countryCode: "",
    languageCode: "",
    background: "",
    clientTimer: false,
    setupReady: false,
    lastSeenVersion: "",
    showAsAvailable: false,
    desktopNotificationsDismissed: false,
    desktopNotificationsDismissedAt: 0,
  };
  try {
    const response = await xhrRequest({
      method: "GET",
      action: actions.ROOM_DETAILS + `/${roomAlias}`,
    });
    const json = JSON.parse(response);
    res.roomAlias = json.room_alias;
    res.therapistDisplayName = json.therapist_display_name;
    res.therapistPhotoUrl = json.therapist_photo_url;
    res.countryCode = json.country_code;
    res.languageCode = json.language_code;
    res.background = json.background;
    res.clientTimer = json.client_timer;
    res.setupReady = json.setup_ready;
    res.lastSeenVersion = json.last_seen_version || "";
    res.showAsAvailable = json.show_as_available;
    res.desktopNotificationsDismissed = json.desktop_notifications_dismissed;
    res.desktopNotificationsDismissedAt =
      json.desktop_notifications_dismissed_at;
  } catch (e) {
    res.status = e.status;
    res.success = false;
  }
  return res;
};

const requestClientAuthorization = async (roomAlias, display_name, picture) => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.CHECKIN,
      body: JSON.stringify({
        room_alias: roomAlias,
        display_name: display_name,
        picture_data: picture,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const sendChatMessage = async (to, message) => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.SEND_CHAT_MESSAGE,
      body: JSON.stringify({ to, message }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestStartSession = async identities => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.CREATE_SESSION,
      body: JSON.stringify({
        participants: identities,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestIdentityVerification = async () => {
  const res = {
    success: true,
  };
  try {
    await xhrRequest({
      method: "POST",
      action: actions.AUTHORIZE_THERAPIST,
    });
  } catch (e) {
    res.success = false;
    res.error = e;
  }
  return res;
};

const requestEndSession = async () => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.END_SESSION,
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestAddParticipants = async identities => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.ADD_PARTICIPANTS,
      body: JSON.stringify({
        participants: identities,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestSendInvitation = async (
  clientName,
  clientEmail,
  sender,
  message,
) => {
  return await xhrRequest({
    method: "POST",
    action: actions.SEND_INVITATION,
    body: JSON.stringify({
      client_name: clientName,
      client_email: clientEmail,
      sender,
      message,
    }),
  });
};

const requestSendFeedback = async (sesionId, feedback) => {
  return await xhrRequest({
    method: "POST",
    action: actions.SEND_FEEDBACK,
    body: JSON.stringify({
      session_id: sesionId,
      feedback,
    }),
  });
};

const requestUpdateRoom = async (
  roomAlias,
  displayName,
  background,
  clientTimer,
) => {
  return await xhrRequest({
    method: "POST",
    action: actions.UPDATE_ROOM,
    body: JSON.stringify({
      room_alias: roomAlias,
      display_name: displayName,
      background,
      client_timer: clientTimer,
    }),
  });
};

const requestDismissDesktopNotifications = async roomAlias => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.UPDATE_ROOM,
      body: JSON.stringify({
        room_alias: roomAlias,
        desktop_notifications_dismissed: true,
      }),
    });
    return {
      success: true,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};

const requestDismissWhatsNew = async (roomAlias, version) => {
  return await xhrRequest({
    method: "POST",
    action: actions.UPDATE_ROOM,
    body: JSON.stringify({
      room_alias: roomAlias,
      last_seen_version: version,
    }),
  });
};

const requestSetAvailability = async (roomAlias, available) => {
  return await xhrRequest({
    method: "POST",
    action: actions.UPDATE_ROOM,
    body: JSON.stringify({
      room_alias: roomAlias,
      show_as_available: available,
    }),
  });
};

const requestSessionHistory = async ({
  offset = "0",
  limit = "10",
  startFilter = "",
  nameFilter = "",
}) => {
  let action = `${actions.SESSION_HISTORY}?limit=${limit}&offset=${offset}`;
  if (startFilter !== "") {
    action = `${action}&start=${startFilter}`;
  }
  if (nameFilter !== "") {
    action = `${action}&client_name=${nameFilter}`;
  }
  try {
    const response = await xhrRequest({
      action,
      method: "GET",
    });
    const json = JSON.parse(response);
    return {
      ...json,
      success: true,
    };
  } catch (e) {
    return {
      success: false,
      status: e.status,
    };
  }
};

const requestBaa = async () => {
  const res = {
    presignedUrl: "",
    success: true,
  };
  try {
    const response = await xhrRequest({
      method: "GET",
      action: actions.BAA,
    });
    const json = JSON.parse(response);
    res.presignedUrl = json.presigned_url;
  } catch (e) {
    return {
      success: false,
    };
  }
  return res;
};

const participantCache = {};

const getParticipantInfo = async participantId => {
  if (participantCache[participantId]) {
    return participantCache[participantId];
  }
  const res = {
    identity: "",
    displayName: "",
    photoUrl: "",
    participantType: "",
    success: true,
  };
  try {
    const response = await xhrRequest({
      method: "GET",
      action: actions.GET_PARTICIPANT_INFO + `/${participantId}`,
    });
    const data = JSON.parse(response);

    res.identity = data.identity;
    res.displayName = data.display_name;
    res.photoUrl = data.photo_url;
    res.participantType = data.participant_type;

    // only cache successful responses
    if (data.identity === participantId) {
      participantCache[participantId] = res;
    }

    return res;
  } catch (e) {
    return {
      ...res,
      success: false,
    };
  }
};

export {
  requestIdentityVerification,
  requestWaitingRoomDetails,
  requestClientAuthorization,
  requestStartSession,
  requestDismissWhatsNew,
  requestEndSession,
  requestAddParticipants,
  requestSendInvitation,
  requestSendFeedback,
  requestUpdateRoom,
  requestSessionHistory,
  sendChatMessage,
  requestBaa,
  requestSetAvailability,
  requestDismissDesktopNotifications,
  getParticipantInfo,
};
