import React from "react";
import Select from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";

const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

export const MonthDropdown = ({ onChange, month, ...rest }) => {
  const months = useCopy([
    "global.january",
    "global.feburary",
    "global.march",
    "global.april",
    "global.may",
    "global.june",
    "global.july",
    "global.august",
    "global.september",
    "global.october",
    "global.november",
    "global.december",
  ]);
  const options = months.map((v, i) => ({ value: i, text: v }));

  return (
    <Select
      {...rest}
      id="monthSelection"
      onChange={e => onChange(parseInt(e.target.value))}
      value={month}
      options={options}
    />
  );
};

export const DayDropdown = ({ month, day, onChange, ...rest }) => {
  const now = new Date();
  const currentMonth = parseInt(now.getMonth());
  const dayOffset = month === currentMonth ? now.getDate() : 1;
  const options = Array.from(
    new Array(daysInMonth(month, now.getFullYear()) + 1 - dayOffset),
  )
    .map((_, d) => d + dayOffset)
    .map(i => ({ value: i, text: i }));
  return (
    <Select
      {...rest}
      id="monthSelection"
      onChange={e => onChange(parseInt(e.target.value))}
      value={day}
      options={options}
    />
  );
};

const generateTimes = date => {
  const startHour = 0;
  const endHour = 24;
  const minuteIncrements = 15;
  return [...new Array((endHour - startHour) * (60 / minuteIncrements))].map(
    (x, i) =>
      new Date(
        new Date(date).setHours(
          startHour + Math.floor(i / (60 / minuteIncrements)),
          (i % (60 / minuteIncrements)) * minuteIncrements,
          0,
          0,
        ),
      ),
  );
};
const nextIncrement = date => {
  const quarterHour = Math.ceil(date.getMinutes() / 15);
  const hour = date.getHours();
  return new Date(
    new Date().setHours(
      hour + (quarterHour === 4 ? 1 : 0),
      (quarterHour % 4) * 15,
      0,
      0,
    ),
  );
};

export const TimeInput = ({ value, onChange, ...rest }) => {
  const [hour, minutes] = value.split(":");
  const current = new Date(new Date().setHours(hour, minutes, 0, 0));
  const times = generateTimes(current);
  const options = times.map(d => ({
    value: d,
    text: new Date(d).toLocaleTimeString([], { timeStyle: "short" }),
  }));
  const handleChange = e => {
    const [newHour, newMinutes] = new Date(e.target.value)
      .toTimeString()
      .split(":");
    onChange(`${newHour}:${newMinutes}`);
  };
  return (
    <Select
      {...rest}
      id="timeSelection"
      onChange={handleChange}
      value={nextIncrement(current)}
      options={options}
    />
  );
};
