import React, { useState, useEffect } from "react";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import { requestWaitingRoomDetails } from "../../httpapi";
import { useParams } from "react-router-dom";
import UnknownError from "../UnknownError";
import NotFoundView from "../../pages/NotFoundView";

const { WAITROOM_SET_THERAPIST_AVAILABLE } = actions;

const getWaitingRoomDetails = (onSuccess, onFailure) => async roomAlias => {
  // get room details from server
  const result = await requestWaitingRoomDetails(roomAlias);

  // callback to success
  if (result.success) {
    return onSuccess(result);
  }

  // callback with failure;
  return onFailure();
};

const { WAITROOM_SET_DISPLAYNAME } = actions;

// WaitingRoomDetails requests room details and stores them in the WaitingRoomContext
export default function WaitingRoomDetails({ type, children }) {
  const { dispatch } = useAppStateContext();
  const { roomAlias: aliasParam } = useParams();
  const { setRoom } = useWaitingRoomContext();
  const [roomError, setRoomError] = useState(false);
  const [loading, setLoading] = useState(true);

  const roomAlias = type === "client" ? aliasParam : "-";
  const ErrorView = type === "client" ? NotFoundView : UnknownError;

  useEffect(() => {
    getWaitingRoomDetails(
      result => {
        setRoom({
          roomAlias: result.roomAlias,
          therapistDisplayName: result.therapistDisplayName,
          therapistPhotoUrl: result.therapistPhotoUrl,
          countryCode: result.countryCode,
          languageCode: result.languageCode,
          background: result.background,
          clientTimer: result.clientTimer,
          setupReady: result.setupReady,
          lastSeenVersion: result.lastSeenVersion,
          desktopNotificationsDismissed: result.desktopNotificationsDismissed,
          desktopNotificationsDismissedAt:
            result.desktopNotificationsDismissedAt,
        });
        dispatch({
          type: WAITROOM_SET_DISPLAYNAME,
          displayName: result.therapistDisplayName,
        });
        dispatch({
          type: WAITROOM_SET_THERAPIST_AVAILABLE,
          available: result.showAsAvailable,
          isInitialState: true,
        });
        setLoading(false);
      },
      () => {
        setRoomError(true);
        setLoading(false);
      },
    )(roomAlias);
  }, [dispatch, roomAlias, setRoom]);

  return <>{loading ? null : roomError ? <ErrorView /> : children}</>;
}
