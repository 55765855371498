/**
 * Component: View Profile
 *
 * This exists to provide a unified UI for the combined profile image / profile
 * name / back to account link that exists in the account menu dropdown and
 * sidebar menu.
 */
import React from "react";
import styled from "styled-components";
import { Link } from "@sussex/react-kit/elements";
import { useWaitingRoomContext } from "../../../providers/WaitingRoomProvider";

import ProfileImage from "./ProfileImage";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
`;

const ViewProfile = ({ profileLink }) => {
  const { room } = useWaitingRoomContext();
  const backToAccount = () => {
    window.location.href = `https://member.psychologytoday.com/${room.countryCode.toLowerCase()}/home`;
  };

  return (
    <Wrapper>
      <ProfileImage
        src={room.therapistPhotoUrl}
        alt={room.therapistDisplayName}
      />
      <Information>
        <Name>{room.therapistDisplayName}</Name>
        <AccountLink onClick={backToAccount}>View Profile</AccountLink>
      </Information>
    </Wrapper>
  );
};

export default ViewProfile;
