import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import useCopyRoomUrl from "../../hooks/useCopyRoomUrl";
import { datadogEvent } from "../../datadog";

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    height: 40px;
    > * {
      text-align: center;
      margin-right: 10px;
    }
  }
`;

const CopyLink = styled.div`
  display: none;
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
`;

const StandaloneSessionURL = styled.div`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SessionURL = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-right: none;
  border-radius: 3px 0 0 3px;
  display: flex;
  padding: 8px;
  width: 100%;
  font-size: 13px;
`;

const Button = styled.button`
  display: block;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  border-radius: 3px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.small};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: white;
  width: 100%;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.light};
  }
  @media (min-width: 768px) {
    width: 125px;
  }
`;

const CopyButton = styled(Button)`
  background: white;
  @media (min-width: 768px) {
    border-radius: 0 3px 3px 0;
  }
`;

const StandaloneCopyButton = styled(CopyButton)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Footer = ({ setShowModal }) => {
  const [
    inviteToSessionText,
    emailLinkText,
    copyLinkText,
    copiedText,
  ] = useCopy([
    "dashboard.invite.title",
    "dashboard.invite.email",
    "dashboard.copyLink",
    "global.copiedToClipboard",
  ]);
  const { copyURL, clicked } = useCopyRoomUrl();
  const { getRoomUrl } = useWaitingRoomContext();

  const copyLink = componentName => {
    datadogEvent({
      category: "invitation",
      feature: "copy_link",
      event: "clicked",
      component: `TherapistVideoPanel.Footer.${componentName}`,
    });

    copyURL();
  };

  const openPanel = () => {
    datadogEvent({
      category: "invitation",
      feature: "panel",
      event: "opened",
      component: `TherapistVideoPanel.Footer`,
    });

    setShowModal(true);
  };

  return (
    <>
      <Title>{inviteToSessionText}</Title>
      <StandaloneSessionURL>{getRoomUrl()}</StandaloneSessionURL>
      <Wrapper>
        <CopyLink>
          <SessionURL>{getRoomUrl()}</SessionURL>
          <CopyButton onClick={() => copyLink("CopyButton")}>
            {clicked ? copiedText : copyLinkText}
          </CopyButton>
        </CopyLink>
        <StandaloneCopyButton onClick={() => copyLink("StandaloneCopyButton")}>
          {clicked ? copiedText : copyLinkText}
        </StandaloneCopyButton>
        <Button onClick={openPanel}>{emailLinkText}</Button>
      </Wrapper>
    </>
  );
};

export default Footer;
