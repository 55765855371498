/**
 * Component: Subnav
 *
 * The Subnav component handles the state and UI for the secondary navigation
 * on desktop devices.
 */
import React, { forwardRef } from "react";
import styled from "styled-components";
import { datadogEvent } from "../../../datadog";

/**
 * Grid container containing 3 columns. Items will be rendered in rows.
 * CSS For this component needs to remain consistent with the work that
 * other teams are doing on this same component in other applications.
 *
 * See {@link https://git.sussexdirectories.com/frontend/components/member/-/blob/master/src/components/header/HeaderSubMenu.vue#L119}
 */
const Wrapper = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;

  position: fixed;
  top: 70px;
  left 50%;
  transform: translateX(-50%);

  padding: 20px;
  background: white;
  box-shadow: 0px 10px 15px rgba(31, 41, 55, 0.1);
  border-radius: 10px;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 700px;
    display: grid;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1018px;
  }
`;

/**
 * SubnavItem is the inner anchor within the Subnav itself
 */
const SubnavItem = styled.a`
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;

  border-radius: 10px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.colors.default};
  font-size: ${({ theme }) => theme.fontSize.normal};
  &:hover {
    background: ${({ theme }) => theme.colors.light};
  }
`;

/**
 * Pull is a small badge next to a SubnavItem. The data for the pill comes in
 * via the `pill` key on the nav item.
 */
const Pill = styled.span`
  background: ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 20px;
  align-items: center;
  font-size: 11px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: white;
  margin-left: 5px;
  padding: 7px 12px 5px 12px;
  line-height: 11px;
  text-align: center;
`;

/**
 * Track nav clicks to datadog before navigating.
 */
const handleClick = item => {
  datadogEvent({
    category: "navigation",
    feature: item.id,
    event: "clicked",
    component: "Header.Navigation",
  });

  window.location.href = item.url;
};

const Subnav = forwardRef(({ id, items, ...rest }, ref) => {
  /**
   * For now the sessions subnav should never open. It displays in a sidebar.
   */
  if (id === "sessions") {
    return null;
  }
  return (
    <Wrapper ref={ref} {...rest}>
      {items.map(item => (
        <SubnavItem
          onClick={() => handleClick(item)}
          key={item.id}
          href={item.url}
        >
          <>
            {item.label}
            {item.pill && <Pill>{item.pill.label}</Pill>}
          </>
        </SubnavItem>
      ))}
    </Wrapper>
  );
});

export default Subnav;
