/**
 * Component: Navigation
 *
 * The Navigation component handles the state and UI for the top level desktop
 * navigation menu. This component will be hidden, via css, when we are at
 * mobile screen widths.
 */
import React, { useEffect, useState } from "react";
import useMouseLeave from "use-mouse-leave";

import Subnav from "./Subnav";

import useNav from "../../hooks/useNav";
import styled, { css, useTheme } from "styled-components";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";

/**
 * This is the wrapper for the top level navigation items, currently residing
 * in the middle of the Header, while in desktop view only.
 */
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * NavLink is a top level navigation anchor, which may or may not have children
 */
const NavLink = styled.a`
  text-decoration: none;
  display: block;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: white;
  white-space: nowrap;

  margin-right: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: ${({ theme }) => theme.fontSize.large};
    margin-right: 35px;
  }
  @media (min-width: 960px) {
    margin-right: 70px;
  }
  &:last-child {
    margin-right: 0;
  }
  padding: 10px 10px 5px 10px;
  ${({ active }) =>
    active
      ? css`
          border-bottom: 2px solid white;
          padding: 10px 0px 5px 0px;
          margin-left: 10px;
          margin-right: 10px;
        `
      : css`
          border-bottom: 2px solid transparent;
          border-radius: 10px;
          &:hover {
            background: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(20px);
            cursor: pointer;
          }
        `}
`;

const Navigation = () => {
  // The useNav hook handles fetching the data for the top level nav items, and
  // their children
  useNav();
  const [mouseLeft, subnavRef] = useMouseLeave();

  const [activeSubnav, setActiveSubnav] = useState("");
  const [subnavTimer, setSubnavTimer] = useState(null);

  const {
    state: { mainNav },
  } = useAppStateContext();

  const {
    breakpoints: { desktop: desktopBreakpoint },
  } = useTheme();

  const isDesktop = () =>
    window.matchMedia(`(min-width: ${desktopBreakpoint})`).matches;

  /**
   * Awful function to determine if a submenu should open up when it's hovered.
   * Desktop nav links with children, unless the nav item is the "sessions" item
   * because that is handled via sidebar.
   *
   * Ideally we should just open all submenu's on hover if they exist.
   */
  const shouldOpenOnHover = item => {
    return isDesktop() && item.children && item.id !== "sessions";
  };

  /**
   * Track nav clicks to datadog before navigating.
   */
  const handleClick = item => {
    if (isDesktop() || !item.children) {
      datadogEvent({
        category: "navigation",
        feature: item.id,
        event: "clicked",
        component: "Header.Navigation",
      });

      return (window.location.href = item.url);
    }

    // This is a mobile screen width and the nav item clicked has children, so we
    // want to simply open the subnav.
    setActiveSubnav(item.id);
  };

  /**
   * When the mouse enters a top level nav item. We need to clear any running
   * timers that may close the subnavigation.
   */
  const handleEnter = item => {
    // If we have mouseentered over the parent NavLink we keep the subnav open
    // Otherwise we have mouseentered a different NavLink and can close the
    // Subnav
    if (item.id === activeSubnav) {
      clearTimeout(subnavTimer);
    } else {
      setActiveSubnav(item.id);
    }
  };

  /** Event that fires when the mouse exits a top level NavLink. This event
   * seems to fire consistently, but if we notice issues we may need to convert
   * to a useMouseLeave hook.
   */
  const handleLeave = item => {
    // If the NavLink we are exiting has children, we set a short timer that
    // when reached will close the subnav. This allows the user to mouse into
    // the subnav without it closing immediatly
    if (item.children) {
      setSubnavTimer(setTimeout(() => setActiveSubnav(""), 300));
    }
  };

  /**
   * Event that fires when we enter the main Subnav. We want to cancel the
   * timeout that we set when we leave the parent NavLink item. This way the
   * subnav remains open until the mouse leaves the Subnav element
   */
  const handleSubnavEnter = () => {
    clearTimeout(subnavTimer);
  };

  const handleClose = () => {
    setActiveSubnav("");
  };

  /**
   * Listen to the mouseLeft value from the useMouseLeave hook and close the
   * subnav if the mouse has left the Subnav component
   */
  useEffect(() => {
    if (mouseLeft) {
      setActiveSubnav("");
    }
  }, [mouseLeft]);

  if (mainNav === null) {
    return null;
  }

  return (
    <Wrapper>
      {mainNav.children.map(item => (
        <React.Fragment key={item.id}>
          <NavLink
            onClick={e => {
              e.preventDefault();
              handleClick(item);
            }}
            onMouseEnter={
              shouldOpenOnHover(item) ? () => handleEnter(item) : null
            }
            onMouseLeave={
              shouldOpenOnHover(item) ? () => handleLeave(item) : null
            }
            href={item.url}
            active={item.id === "sessions"}
          >
            {item.label}
          </NavLink>
          {item.children && activeSubnav === item.id && (
            <Subnav
              id={item.id}
              label={item.label}
              items={item.children}
              ref={subnavRef}
              onMouseEnter={shouldOpenOnHover(item) ? handleSubnavEnter : null}
              onClose={handleClose}
            />
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Navigation;
